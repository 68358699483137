// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-curso-tecnico-js": () => import("./../src/pages/curso-tecnico.js" /* webpackChunkName: "component---src-pages-curso-tecnico-js" */),
  "component---src-pages-eja-js": () => import("./../src/pages/eja.js" /* webpackChunkName: "component---src-pages-eja-js" */),
  "component---src-pages-graduacao-js": () => import("./../src/pages/graduacao.js" /* webpackChunkName: "component---src-pages-graduacao-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pos-graduacao-js": () => import("./../src/pages/pos-graduacao.js" /* webpackChunkName: "component---src-pages-pos-graduacao-js" */),
  "component---src-templates-cursos-post-js": () => import("./../src/templates/cursos-post.js" /* webpackChunkName: "component---src-templates-cursos-post-js" */)
}

